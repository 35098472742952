.payments {
	color: $default;

	&__title {
		font-weight: 500;
		font-size: 25px;
		margin-bottom: 30px;
	}
}

.receipt {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px;
		font-size: 16px;
	}
}
