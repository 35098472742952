.terminalSettings {
	&__content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}

	&__title {
		margin-bottom: 15px;
		font-weight: 600;
		&._2 {
			font-size: 24px;
		}
	}

	&__subtitle {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
	}

	&__config {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-bottom: 40px;

		button {
			width: 250px;
		}
	}

	&__config-download {
		margin-bottom: 40px;
		max-height: 500px;
		overflow-y: auto;
	}

	&__rekalame-item {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__inner {
		display: flex;
		max-width: 600px;
		align-items: center;

		& > *:not(:last-child) {
			margin-right: 15px;
		}
	}

	&__item {
		&:not(:last-child) {
			border-right: 1px solid #ccc;
		}
	}

	&__list-active {
		font-size: 14px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		margin: 10px 0;

		&-item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			padding: 4px;
			border-radius: 4px;
			border: 1px solid $default;
		}
	}

	&__main {
		padding-bottom: 19px;
		border-bottom: 1px solid $accent;
		margin-bottom: 20px;
	}

	&__main-btn {
		margin-top: 20px;
	}

	&__config-device {
		&-item {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 40px;
		}
	}

	&__maintenance-title {
		margin-bottom: 15px;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		button {
			width: 125px;
		}
	}

	&__left {
		flex: 1;
		border-right: 1px solid #ccc;
		padding: 16px;

		&-inner {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}

	&__right {
		flex: 1;
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	&__qr-block {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	&__label {
		font-weight: 500;
		margin: 0;
	}

	&__chosen {
		margin: 0;
		font-size: 14px;
	}
}

.qr-modal {
	&-form {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&-input {
			.ant-input {
				width: 100%;
				padding: 8px 12px;
				border-radius: 4px;
				border: 1px solid #d9d9d9;
				transition: all 0.3s;

				&:focus {
					border-color: #40a9ff;
					box-shadow: 0 0 0 2px rgba(#1890ff, 0.2);
				}
			}
		}

		&-submit {
			margin: auto;
			display: block;
			width: max-content;
		}
	}
}
