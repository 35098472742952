.readings__tabs {
	.ant-tabs-nav {
		margin-bottom: 0;
	}
}

.readings {
	&__pagination {
		margin: 16px 0;
		justify-content: flex-end;
		margin-left: auto;
	}
	&__inner {
		background-color: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(5px);
		border-radius: 0 20px 20px;
		padding: 20px;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	}
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		font-weight: 500;
		font-size: 25px;
		margin-bottom: 30px;
	}
	&__filter {
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;
		gap: 6px;
		width: 300px;
		&-text {
			margin-bottom: 5px;
		}
	}
}
