.rtsp {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;

	& canvas {
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
	}
	&__close {
		position: absolute;
		top: -15px;
		right: -15px;
		background: white;
		font-size: 25px;
		border-radius: 50%;
		padding: 5px;
		opacity: 0.3;
		cursor: pointer;
		z-index: 11000;

		&:hover {
			opacity: 1;
		}
	}
}
