.vnc {
	height: 22vh;
	width: 100%;
	position: relative;
	transition: all 0.3s ease;
	& canvas {
		cursor: default !important;
	}
	&--fullscreen {
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		background: #000;
		border-radius: 10px;
		div[style*='height: 100vh'] {
			height: 100% !important;
		}
	}
	&__arrows {
		position: absolute;
		bottom: 10px;
		right: 10px;
		background: white;
		padding: 5px;
		font-size: 30px;
		cursor: pointer;
		border-radius: 7px;
		opacity: 0.3;
		z-index: 11000;
		&:hover {
			opacity: 1;
		}
	}
	&__close {
		position: absolute;
		top: -15px;
		right: -15px;
		background: white;
		font-size: 25px;
		border-radius: 50%;
		padding: 5px;
		opacity: 0.3;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}
}
