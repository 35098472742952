.work-time {
	margin-bottom: 12px;

	&__title {
		margin-bottom: 15px;
		font-weight: 600;
	}

	&__days-container {
		display: flex;
		gap: 16px;
		overflow-x: auto;
		margin-bottom: 12px;
		padding-bottom: 8px;

		&::-webkit-scrollbar {
			height: 6px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 3px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 3px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	&__day {
		min-width: 120px;
		padding: 8px;
		border: 1px solid #d9d9d9;
		border-radius: 8px;
		position: relative;
		background: #fff;
		transition: all 0.3s;

		&--error {
			border-color: #ff4d4f;
		}
	}

	&__day-label {
		margin-bottom: 8px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);

		&--error {
			color: #ff4d4f;
		}
	}

	&__inputs {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	// Оригинальные стили из примера
	&__item-calendar {
		min-height: 80px;
		border: 0;
		display: block;
		width: auto;
		height: 100px;
		border-top: 2px solid rgba(5, 5, 5, 0.06);
		border-radius: 0;
		padding: 10px 15px;
		overflow-y: auto;
		margin: 0 3px;

		&._weekday {
			background: #e1faeb;
		}

		&._weekend {
			background: #fae1e1;
		}

		&._edit {
			background: #fdfadc;
		}
	}

	&__item-date {
		margin-bottom: 5px;
	}

	&__submit-button {
		width: 100%;
		max-width: 240px;
	}

	& .ant-picker-cell-selected {
		& .work-time__item-calendar {
			background: #e6f4ff;
		}
	}
	& .ant-picker-month-panel .ant-picker-cell {
		border-top: 2px solid rgba(5, 5, 5, 0.06);
		border-right: 2px solid rgba(5, 5, 5, 0.06);
		padding: 10px 15px !important;
	}
	&__info-inner {
		display: flex;
		margin-bottom: 30px;
	}
	&__info-inner-color {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 5px;
	}
	&__info-inner-item {
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-right: 15px;
			&::after {
				content: ', ';
			}
		}
	}
	&__modal-footer {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > *:not(:last-child) {
			margin-right: 20px;
		}
	}
	&__item-text {
		font-size: 12px;
		padding: 2px 5px;
		border-radius: 2px;
		background-color: #cfb8329a;
	}
	&__item-time {
		font-size: 12px;
	}
	&__modal-about {
		margin-bottom: 15px;
	}
	&__modal-edit-text {
		margin-bottom: 15px;
		& span {
			font-weight: 500;
		}
	}
	&__modal-time {
		margin-bottom: 15px;
	}
	&__modal-time-edit {
		margin-bottom: 15px;
	}
	&__modal-fulltime-warning {
		font-size: 13px;
		font-weight: 500;
	}
	&__modal-fulltime-btn.ant-btn-primary {
		background: #e7cc31;
		&:hover {
			background: #ffe23d !important;
		}
	}
	&__default-time {
		display: inline-flex;
	}
	&__default-time-btn {
		display: flex;
		margin-top: 20px;
	}
	&__default-eror {
		color: $red;
		margin-top: 5px;
		font-size: 13px;
		display: none;
		&._active {
			display: block;
		}
	}
	&__modal-time-edit-title {
		margin-bottom: 10px;
	}
	&__modal-segmented {
		margin-bottom: 10px;
		& .ant-segmented-thumb {
			background: $accent !important;
			color: $white !important;
			border-radius: 12px !important;
		}
		& .ant-segmented-item {
			position: relative !important;
			border: none;
			overflow: hidden;
			border-radius: 12px !important;
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(0deg, #7497cd 15%, #0e4da4 100%);
				opacity: 0;
				z-index: 0;
				transition: opacity 0.3s;
			}
			&:active {
				background: $accent;
				background-size: 120%;
			}
			&.ant-segmented-item-selected {
				background: $bgGrad;
				background-size: 120%;
			}
			&:hover {
				background-color: $grey;
			}
		}
	}
}
