.device {
	padding: 0 5px;
	position: relative;
	transition: box-shadow 0.3s ease;
	text-align: center;

	&__title {
		font-size: 16px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1px;
		gap: 5px;

		& img {
			height: 25px;
		}
	}

	&__status {
		font-size: 15px;
		&--success {
			color: green;
		}
		&--error {
			color: red;
		}
		&._small {
			font-size: 13px;
			color: rgb(41, 58, 212);
			max-width: 120px;
			line-height: 1.1;
		}
	}
}
