.terminal__tabs {
	.ant-tabs-nav {
		margin-bottom: 0;
	}
}

.terminal {
	padding: 15px 15px;
	background-color: $white;
	border-radius: 0 20px 20px;
	border: 1px solid $accent;
	flex: 1;
	display: flex;
	flex-direction: column;

	&__full {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 0;
		z-index: 100;
	}

	& .ant-tabs-nav {
		margin: 0;
	}

	& .ant-tabs-nav::before {
		display: none;
	}

	&__main {
		&-wrap {
			display: flex;
			grid-gap: 20px;
			display: grid;
			grid-template-columns: 2fr 1fr;
			align-items: stretch;
			flex: 1;
		}

		&-camera {
			min-height: 30vh;
			// max-height: 75vh;

			& iframe {
				width: 100%;
				height: 100%;
			}
		}
		&__modal-name {
			text-align: center;
			margin-bottom: 20px;
		}

		&-camera,
		&-vnc {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 15px;
			background: var(--title-color);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

			&-btn {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 250px;

				button {
					transition: all 0.3s ease;
					&:hover {
						background: #4a90e2;
						color: #fff;
					}
				}
			}
		}

		&-wrap-item_2 {
			display: grid;
			grid-template-rows: 1fr 125px;
			gap: 20px;
			flex: 1;
		}

		&-wrap-item_3 {
			display: grid;
			gap: 20px;
			grid-template-rows: 22vh 60px 3fr auto;
			& h2 {
				font-weight: 400;
				font-size: 18px;
			}
		}

		&-appeal {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10px;

			button {
				transition: all 0.3s ease;
				width: 100%;
				height: 45px;

				&.green {
					background-color: #5cb85c;
				}
				&.orange {
					background-color: #f0ad4e;
					color: #fff;

					&:disabled {
						opacity: 0.5;
					}
				}
			}

			> div {
				display: flex;
				flex-direction: row;
				gap: 10px;
			}
		}

		&-subscriber {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10px;
			margin-top: 10px;
		}
	}

	&__devices {
		&-wrap {
			margin-top: 10px;
			padding-top: 10px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 10px;
			border-top: 1px solid $accent;
		}
	}

	&__modal {
		& .ant-modal-title {
			text-align: center;
			font-size: 24px;
			font-weight: bold;
		}

		& .ant-btn-primary {
			//	background: linear-gradient(145deg, #4a90e2, #357abd);
			//	border: none;
			//	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
			//	transition: background 0.3s ease;

			&:hover {
				//		background: #4a90e2;
			}
		}
	}
	&__modal-name {
		text-align: center;
		margin-bottom: 20px;
	}

	&__modal-input {
		margin: 20px 0;
	}
	&__appeals-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;

		& button {
			width: max-content;
		}
	}
	&__appeals-table {
		overflow-y: auto;
	}
	&__modal-printer-btn {
		margin: 10px auto 30px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		color: $white;
		&:disabled {
			color: rgba(255, 255, 255, 0.4);
		}
	}
	&__modal-printer-item-del {
		background: #ff4d4f !important;
	}
	&__modal-printer-stack {
		text-align: center;
		font-weight: 700;
		margin-bottom: 10px;
	}
	&__modal-printer-list {
		border: 1px solid $default;
		border-radius: 10px;
		max-height: 200px;
		overflow-y: auto;
	}
	&__docs-section {
		margin-top: 30px;
		& h2 {
			margin-bottom: 5px;
		}
	}
	&__modal-printer-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 15px;
		font-size: 16px;
		&:not(:last-child) {
			border-bottom: 1px solid $default;
		}
	}
	&__appeals-bottom-btn {
		width: 300px;
		font-size: 16px;
		padding: 10px;
		background: linear-gradient(145deg, #4a90e2, #357abd);
		color: #fff;
		border: none;
		border-radius: 10px;
		transition:
			background 0.3s ease,
			box-shadow 0.3s ease;

		&:hover {
			background: #357abd;
			box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
		}
	}
	&__modal-scanner-btn {
		width: 100%;
		margin-bottom: 15px;
	}

	&__modal-scanner-wrap {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}
	&__modal-scanner-preview {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px;
		height: 53vh;
		box-sizing: border-box;
	}
	&__modal-wrap-pdf-btn {
		display: flex;
		width: 80px;
		height: 80px;
		margin-bottom: 10px;
		min-width: 0;
	}
	&__appeals-item-title {
		font-weight: 500;
		font-size: 17px;
	}
	&__appeals-date {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 15px;
		margin-top: 10px;
	}
	&__appeals-big-title {
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 500;
	}
	&__appeals-top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 30px;
	}
	&__appeals-left {
		margin-right: 30px;
		flex: 1;
	}
	&__appeals-right {
		flex: 1;
	}
	&__appeals-doc-title {
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 10px;
	}
	&__appeals-call {
		margin-bottom: 30px;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		& h2 {
			font-size: 26px;
			margin-bottom: 10px;
		}
	}
}
